<template>
  <div class="bd-example" data-example-id="">
    <div class="form-group row">
      <label class="col-1 col-form-label">{{ $t('loaders.logotypes.logotype.name') }}</label>
      <div class="col-2">
        <input class="form-control" type="text" v-model="data.name">
        <span v-if="validerr.get('iso')" class="validerr">{{validerr.get('iso')}}</span>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-1 col-form-label">{{ $t('loaders.logotypes.logotype.file') }}</label>
      <div class="col-2">
        <input class="form-control" type="file" id="file" ref="fileInput" @change="handleFileUpload($event.target.files)">
        <span class="validerr">{{validerr.get('iso')}}</span>
      </div>
    </div>
  </div>

  <div v-if="!data.file_path && state=='edit'">{{ $t('loaders.logotypes.logotype.notImage') }}</div>
  <img class="logoimg" v-if="data.file_path" :src="full_path">

  <p></p>
  <div class="row">
    <div class="col-md-2">
      <button @click="save" type="button" class="btn btn-success" :title="$t('game.game.ok')">Ok</button>
      <button @click="closeEdit" type="button" class="btn btn-danger" :title="$t('game.game.cancel')">{{ $t('game.game.cancel1') }}</button>
    </div>
  </div>
</template>

<script setup>
import LogotypesService from "../../../services/logotypes.service";
import {ref, defineEmits, defineProps, inject, onMounted, computed, watch, provide} from "vue";
import { getCurrentInstance } from 'vue'


const $t = inject('$t')

const state = inject('state')
const setState = inject('setState')
const sel_row = inject('sel_row')

const fileInput = ref(null)
const file = ref('')
const full_path = ref('')

const set_result = inject('set_result')

const data = ref({
  name: ''
})

const validatas = inject('validatas')
const validerr = ref(new Map())
const verdata = ref(new Map([
  ['name', {reg: '[A-Z][A-Z][A-Z]'}],
]));

function closeEdit()
{
  setState('list')
}

function handleFileUpload(files)
{
  file.value=files[0]
}

function getLogotype()
{
  LogotypesService.getLogotypes(sel_row.value).then(
      (response) => {
        data.value = response.data[0];

        if (data.value.file_path!=null) {
          full_path.value=data.value.files_path+data.value.file_path
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function save()
{
  let formData = new FormData();
  formData.append("file", file.value);
  formData.append("name", data.value.name);

  if(state.value=='edit') {
    formData.append("id", data.value.id);
  }

  LogotypesService.saveLogotypes(formData).then(
      () => {
        setState('list')
      },
      (error) => {
        let nam_err=''
        let err_data=error.response.data.err_info
        if(err_data.indexOf('###not_unic_name###')!=-1) {
          nam_err=$t('loaders.logotypes.logotype.not_unic_name')
        }

        set_result(error.response.status, error, nam_err)
      }
  )
}

onMounted(() => {
  if(state.value=='edit') {
    getLogotype()
  }
})
</script>

<style scoped>
.logoimg {
  max-width: 90%;
}
</style>
