<template>
  <button @click="setState('edit', selRow)" type="button" class="btn btn-primary p-1">
    <div class="icon-size"><font-awesome-icon icon="pencil-alt" /></div>
  </button>
</template>

<script setup>
import {ref, defineEmits, defineProps, inject} from "vue";

const props = defineProps({
  selRow: String
});

const setState = inject('setState')
</script>
