<template>
  <p></p>
  <div class="container-fluid">
    <button v-if="state=='list'" @click="setState('new')" class="btn-success">{{ $t('loaders.logotypes.logotypes.addLogo') }}</button>
    <p></p>
    <vue-good-table v-if="state=='list'"
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: 100,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'id', type: 'asc'}
        }"
        compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
        <CurrencyActions v-if="props.column.field == 'actions'" :selRow="props.row.id"></CurrencyActions>
      </template>
    </vue-good-table>

    <Currency v-if="state!='list'"></Currency>
  </div>
</template>

<script setup>
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import CurrencyService from "../../../services/currencie.service";
import CurrencyActions from "./LogotypeActions.vue";
import Currency from "./Logotype.vue";
import {ref, inject, computed, onMounted, provide, watch} from "vue";
import LogotypesService from "../../../services/logotypes.service";

const sortByInt = inject('sortByInt')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const set_result = inject('set_result')

const sel_row = ref('')
provide('sel_row', sel_row)

const state = ref('list')
provide('state', state)

function setState(st, id='')
{
  state.value=st
  sel_row.value=id

  if(st=='list') {
    getLogotypes()
  }
}
provide('setState', setState)

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
      {
        label: $t('loaders.logotypes.logotypes.id'),
        field: 'id',
        filterOptions: {enabled: true},
        width: '100px',
        sortFn: sortByInt,
      },
      {
        label: $t('loaders.logotypes.logotypes.name'),
        field: 'name',
        filterOptions: {enabled: true}
      },
      {
        label: $t('app.public.action'),
        field: 'actions',
        html: true,
        width: '100px',
      },
    ])
const rows = ref([])

function getLogotypes()
{
  LogotypesService.getLogotypes().then(
    (response) => {
      rows.value = response.data;
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

onMounted(() => {
  getLogotypes()
})
</script>
